<template>
  <div
    class="modal fade repeat-alert-modal"
    :id="'CustomizeAlertModal'+pid"
    tabindex="-1"
    role="dialog"
    aria-labelledby="CustomizeAlertModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="CustomizeAlertModalLabel">
              Customize Alert
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <h6>Customize Available in Item. Go to cart to remove the item.</h6>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">No</button>
          <button type="button" :style="colorObj" class="btn customize-button hoverEffect" @click="goToCart()">Yes</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helper from "../../store/helper"
export default {
  name: "CustomizeAlert",
  props:{
    pid: null
  },
  mixins:[helper],
  data() {
    return {
      currency_symbol: "₹",
    };
  },
  mounted() {
    this.originalColor();
  },
  methods: {
    goToCart(){
      window.$('#CustomizeAlertModal'+this.pid).modal("hide");
      this.$router.push('/cart');      
    },
  }
};
</script>
<template>
  <div
    class="modal fade AvailableOption"
    :id="'AvailableOption'+pid"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    ref="vuemodal"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <h2 class="section-heading text-center">{{item_name}}</h2>
          
          <form class="available-option" v-if="item_variant!=null">
            <div class="form-fields">
              <div
                class="section-row"
                v-for="(choice,index) in item_variant.choices"
                v-bind:key="'CM'+ index"
              >
                <h3>{{choice.variant_choice_name}}</h3>
                <p>Please select any one option</p>

                <div
                  class="form-row"
                  v-for="(choice_price, vindex) in choice.choice_prices"
                  v-bind:key="'CS'+ vindex"
                >
                  <div class="d-flex flex-wrap radio-dt">
                    <input
                      type="radio"
                      v-model="selectedChoices[index]"
                      :value="choice_price"
                      :id="choice_price.variant_sub_choice_name+pid+choice_price.variant_choice_price_id"
                    >
                    <label :for="choice_price.variant_sub_choice_name+pid+choice_price.variant_choice_price_id">
                      <span class="d-inline-block">{{choice_price.variant_sub_choice_name}}</span>
                    </label>
                  </div>
                  <div class="pricing">
                    <span v-html="priceFormatter(choice_price.price)"></span>
                  </div>
                </div>
              </div>

              <div
                class="section-row"
                v-for="(addon,ind) in item_variant.addons"
                v-bind:key="'AM'+ ind"
              >
                <h3>{{addon.variant_addon_name}}</h3>
                <p>You can choose multiple options</p>
                <div
                  class="form-row"
                  v-for="(addon_price, vind) in addon.addon_prices"
                  v-bind:key="'AS'+ vind"
                >
                  <div class="d-flex flex-wrap radio-dt">
                    <input
                      type="checkbox"
                      v-model="selectedAddons"
                      :value="addon_price"
                      :id="addon_price.variant_sub_addon_name+pid+addon_price.variant_addon_price_id"
                      :name="addon.variant_addon_name"
                    >
                    <label :for="addon_price.variant_sub_addon_name+pid+addon_price.variant_addon_price_id">
                      <span class="d-inline-block">{{addon_price.variant_sub_addon_name}}</span>
                    </label>
                  </div>
                  <div class="pricing">
                    <span v-html="priceFormatter(addon_price.price)"></span>
                  </div>
                </div>
              </div>

            </div>

            <div class="button text-center">
              <span class="btn btn-primary" id="aditinalInfo" @click="addChoicesAddons()">
                Add
                <span v-html="priceFormatter(totalPrice)"></span>
              </span>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bus } from "../../main";
import helper from "../../store/helper"
export default {
  name: "AvailableOption",
  props:{
    pid: null
  },
  mixins:[helper],
  data() {
    return {
      currency_symbol: "₹",
      item: null,
      item_index: "",
      item_variant: null,
      item_name: "",
      selected_choice: null,
      selectedChoices: [],
      selectedAddons: [],
      selected_data: {
        item: '',
        index: '',
        item_variant: '',
        addon_choice_price: '',
        choices: '',
        addons: ''
      }
    };
  },
  mounted() {
    bus.$on("ac_item", data => {
      this.item = data;
    });
    bus.$on("ac_item_index", data => {
      this.item_index = data;
    });
    bus.$on("ac_item_variant", data => {
      this.item_variant = data;
    });
    bus.$on("ac_item_name", data => {
      this.item_name = data;
    });
    this.currency_symbol = localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹";
    window.$(this.$refs.vuemodal).on("hidden.bs.modal", this.closeModal);
    window.$(this.$refs.vuemodal).on("show.bs.modal", this.openModal);
    this.originalColor();
  },
  updated(){
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:before { border-top-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:before { border-right-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:before { border-bottom-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:before { border-left-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="radio"] ~ label:after { background-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="checkbox"] ~ label:before { border-top-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="checkbox"] ~ label:before { border-right-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="checkbox"] ~ label:before { border-bottom-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="checkbox"] ~ label:before { border-left-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.available-option .radio-dt input[type="checkbox"] ~ label:after { background-color: '+localStorage.getItem("button_default_color")+' }</style>').appendTo('body')
    window.$('<style>.available-option span.btn-primary { background-color: '+localStorage.getItem("button_default_color")+' !important }</style>').appendTo('body')
    window.$('<style>.available-option span.btn-primary:hover{ background-color: '+localStorage.getItem("button_hover_color")+' !important }</style>').appendTo('body')
    //window.$('.available-option .btn-primary:hover').css("background-color",localStorage.getItem("button_hover_color"))
  },
  computed: {
    totalPrice: function() {
      let sum = 0;
      if (this.selectedChoices.length > 0) {
        this.selectedChoices.forEach(value => {
          sum += parseFloat(value.price);
        });
      }
      if (this.selectedAddons.length > 0) {
        this.selectedAddons.forEach(value => {
          sum += parseFloat(value.price);
        });
      }
      return parseFloat(sum).toFixed(2);
    }
  },
  methods: {
    addChoicesAddons(){
      this.selected_data.item = this.item;
      this.selected_data.index = this.item_index;
      this.selected_data.item_variant = this.item_variant;
      this.selected_data.addon_choice_price = this.totalPrice;
      this.selected_data.choices = this.selectedChoices;
      var _this=this;
      this.selectedAddons.forEach((obj,index) => {
          _this.selectedAddons[index].is_added = 1;
        });
      this.selected_data.addons = this.selectedAddons;
      this.$emit('update', this.selected_data);
      window.$('#AvailableOption'+this.pid).modal("hide");
    },
    priceFormatter(value) {
      var price = value.toString().split(".")[0];
      var decimal = value.toString().split(".")[1];
      var response = this.currency_symbol + price;
      if(decimal != undefined){
        response += "<span class='price-decimal'><small>." + decimal + "</small></span>";
      }
      return response;
    },
    openModal(){
      if(this.item_variant != null && this.item_variant.choices != null){
        this.item_variant.choices.forEach(value => {
          this.selectedChoices.push(value.choice_prices[0]);
        });
      }
    },
    closeModal(){
      this.item = null;
      this.item_index = "";
      this.item_variant = null;
      this.item_name = "";
      this.selectedChoices = [];
      this.selectedAddons = [];
    },
  }
};
</script>
<style>
.radiobtn {
  width: 30px;
  height: 27px;
  margin: 0 -26px 0px -7px;
  color: #23c7fa;
}
</style>
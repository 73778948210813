<template>
  <div class="comboOffer modal fade" :id="'comboOffer'+pid" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" ref="combomodal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body" v-show="combo_loading==false && combo_item_details != null && item != null">
          <h2 class="page-heading text-center">{{comb_item_name}}</h2>
          <div class="row">
            <b-tabs pills vertical content-class="result" nav-wrapper-class="filter" class="combo-list w-100 m-0" v-model="step">
              <b-tab 
                class="nav-link row mx-0" 
                :active="combo_index == '0' ? true : false"
                v-for="(combo_items, combo_index) in combo_item_details"
                v-bind:key="combo_index"
                @click="resetAddonChoice()"
                >
                <template v-slot:title>
                  <span :class="checkMaxComboQty(combo_index) == false ? 'sr-no added' : 'sr-no'">{{combo_index+1}}</span><span class="content-body"><span class="main-title">{{combo_items.combo_item_name}} ({{combo_items.inc_index}} of {{combo_items.total_qty}})</span></span>
                </template>
                <div :class="active_combo_choices.length > 0 || active_combo_addons.length > 0 ? 'combo-result with-choice' : 'combo-result without-choice'">
                  <ul class="row list-unstyled combo-row mb-0">
                    <li :class="active_combo_choices.length > 0 || active_combo_addons.length > 0 ? 'col-md-12' : 'col-md-6'" v-for="(citem, item_index) in combo_items.combo_variants" v-bind:key="item_index">
                      <div :style="comobobColor" :class="isComboItemAdded(combo_index, item_index) === true ? 'shop-main media active' : 'shop-main media'" @click="checkComboSelection(combo_index, item_index)">
                        <v-lazy-image :src="citem.item_image" src-placeholder="citem.item_image" :alt="citem.item_name" class="product-image" />
                        <div class="media-body">
                          <h5>{{citem.item_name}}</h5>
                          <h5 :style="textcolor" v-if="citem.choices != null && citem.addons != null" class="available-label">
                            Choices Addon Available
                          </h5>
                          <h5 :style="textcolor" v-if="citem.choices != null && citem.addons == null" class="available-label">
                            Choices Available
                          </h5>
                          <h5 :style="textcolor" v-if="citem.choices == null && citem.addons != null" class="available-label">
                            Addon Available
                          </h5>
                          <h5>{{citem.brand_name}}</h5>
                          <h5 v-if="parseFloat(citem.unit_quantity) != '0' || citem.unit_name != null || citem.packaging_name != null">
                            <span v-if="parseFloat(citem.unit_quantity) != '0'">
                              {{ Number( citem.unit_quantity ).toFixed(1) }}
                            </span>
                            <span v-if="citem.unit_name != null">
                              {{ citem.unit_name }}
                            </span>
                            <span v-if="citem.packaging_name != null">
                              {{ citem.packaging_name }}
                            </span>
                          </h5>
                        </div>
                        <span class="product-type-category" v-if="citem.item_type == '0'"></span>
                        <span class="product-type-category" v-if="citem.item_type == '1'">
                          <img src="../../assets/images/svgs/veg.svg" height="20" width="20">
                        </span>
                        <span class="product-type-category" v-if="citem.item_type == '2'">
                          <img src="../../assets/images/svgs/nonveg.svg" height="20" width="20">
                        </span>
                        <span class="product-type-category" v-if="citem.item_type == '3'">
                          <img src="../../assets/images/svgs/egg.svg" height="20" width="20">
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="customisation" v-if="active_combo_choices.length > 0 || active_combo_addons.length > 0">
                  <div class="customisation-scroll">
                    <div class="close" @click="resetAddonChoice()">
                      <span>&times;</span>
                    </div>
                    <h2 class="section-heading mb-3 text-center">{{selected_item_name}}</h2>
                    <form class="available-option">
                      <div class="form-fields">

                        <div v-if="active_combo_choices != null">
                          <div
                            class="section-row"
                            v-for="(choice,index) in active_combo_choices"
                            v-bind:key="'CM'+ index"
                          >
                            <h3>{{choice.variant_choice_name}}</h3>
                            <p>Please select any one option</p>

                            <div
                              class="form-row"
                              v-for="(choice_price, vindex) in choice.choice_prices"
                              v-bind:key="'CS'+ vindex"
                            >
                              <div class="d-flex flex-wrap radio-dt">
                                <input
                                  type="radio"
                                  v-model="selectedChoices[index]"
                                  :value="choice_price"
                                  :id="choice_price.variant_sub_choice_name"
                                >
                                <label :for="choice_price.variant_sub_choice_name">
                                  <span class="d-inline-block">{{choice_price.variant_sub_choice_name}}</span>
                                </label>
                              </div>
                              <div class="pricing">
                                <span v-html="priceFormatter(choice_price.price)"></span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div v-if="active_combo_addons != null">
                          <div
                            class="section-row"
                            v-for="(addon,ind) in active_combo_addons"
                            v-bind:key="'AM'+ ind"
                          >
                            <h3>{{addon.variant_addon_name}}</h3>
                            <p>You can choose multiple options</p>
                            <div
                              class="form-row"
                              v-for="(addon_price, vind) in addon.addon_prices"
                              v-bind:key="'AS'+ vind"
                            >
                              <div class="d-flex flex-wrap radio-dt">
                                <input
                                  type="checkbox"
                                  v-model="selectedAddons"
                                  :value="addon_price"
                                  :id="addon_price.variant_sub_addon_name + addon_price.variant_addon_price_id"
                                  :name="addon.variant_addon_name"
                                >
                                <label :for="addon_price.variant_sub_addon_name + addon_price.variant_addon_price_id">
                                  <span class="d-inline-block">{{addon_price.variant_sub_addon_name}}</span>
                                </label>
                              </div>
                              <div class="pricing">
                                <span v-html="priceFormatter(addon_price.price)"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div class="button text-center">
                    <span :style="colorObj" class="btn addons-button hoverEffect" id="aditinalInfo" @click="addChoicesAddons()">
                      Add
                      <span v-html="priceFormatter(totalPrice)"></span>
                    </span>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div class="totle-button">
            <a href="javascript:void(0)" :style="comoboColor" v-if="item!=null && is_wishlist" :class="(totalComboQty != totalSelectedComboItems) ? 'btn button-style w-100 disabled' : 'btn button-style w-100'" @click="addCombo()">ADD TO WISHLIST <span class="ml-1 mr-1">(<span v-html="priceFormatter(totalComboPrice)"></span>)</span></a>
            <a href="javascript:void(0)" :style="comoboColor" v-if="item!=null && !is_wishlist" :class="(totalComboQty != totalSelectedComboItems) ? 'btn button-style w-100 disabled' : 'btn button-style w-100'" @click="addCombo()">ADD TO CART <span class="ml-1 mr-1">(<span v-html="priceFormatter(totalComboPrice)"></span>)</span></a>
          </div>
        </div>
        <div class="modal-body" v-show="combo_loading==true">
          <div class="row">
            <div class="container">
              <p class="text-center">
                <img src="../../assets/images/loading.gif" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { bus } from "../../main";
import helper from "../../store/helper"
import VLazyImage from "v-lazy-image/v2";
export default {
  name: "ComboOption",
  components: {
    VLazyImage,
  },
  props:{
    pid: null,
    is_wishlist: null
  },
  mixins:[helper],
  data() {
    return {
      currency_symbol: "₹",
      combo_loading: false,
      comb_item_name: '',
      combo_item_data: {
        item_id: "",
        user_id: "",
      },
      combo_item_details: null,
      item: null,
      item_index: "",
      item_variant: null,
      comb_cart_id: '',
      selected_data: {
        item: '',
        index: '',
        item_variant: '',
        combo_items: '',
        cart_id: '',
        combo_variants:null
      },
      selectedComboItems: [],
      comboItemsQty: [],
      step: 0,
      active_combo_choices: [],
      active_combo_addons: [],
      active_combo_index: '',
      active_item_index: '',
      selectedChoices: [],
      selectedAddons: [],
      selected_item_name: '',
    };
  },
  computed: {
    totalComboQty() {
      var totalqnty = 0;
      if(this.combo_item_details != null){
        this.combo_item_details.forEach(combo_item => {
          totalqnty += parseFloat(combo_item.quantity);
        });
      }
      return totalqnty;
    },
    totalSelectedComboItems() {
      var totalqty = 0;
      if(this.combo_item_details != null){
        this.combo_item_details.forEach(combo_items => {
          combo_items.combo_variants.forEach(item => {
            if(item.is_added == 1){
              totalqty += parseFloat(item.quantity);
            }
          });    
        });
      }
      return totalqty;
    },
    totalPrice: function() {
      let sum = 0;
      if (this.selectedChoices.length > 0) {
        this.selectedChoices.forEach(value => {
          sum += parseFloat(value.price);
        });
      }
      if (this.selectedAddons.length > 0) {
        this.selectedAddons.forEach(value => {
          sum += parseFloat(value.price);
        });
      }
      return parseFloat(sum).toFixed(2);
    },
    totalComboPrice: function() {
      var sum = 0;
      if (this.combo_item_details != null) {
        
        if(this.item_variant != null)
          sum += parseFloat(this.item_variant.discounted_price);
        
        this.combo_item_details.forEach(items => {
          if(items.combo_variants != null){
            items.combo_variants.forEach(combo_variant => {

              if(combo_variant.choices != null){
                combo_variant.choices.forEach(choice => {
                  choice.choice_prices.forEach(choice_price => {
                    if(choice_price.is_added == 1){
                      sum += parseFloat(choice_price.price);
                    }
                  });
                });
              }

              if(combo_variant.addons != null){
                combo_variant.addons.forEach(addon => {
                  addon.addon_prices.forEach(addon_price => {
                    if(addon_price.is_added == 1){
                      sum += parseFloat(addon_price.price);
                    }
                  });
                });
              }
            });
          }
        });
      }
      return parseFloat(sum).toFixed(2);
    }
  },
  mounted() {
    bus.$on("comb_item", data => {
      this.item = data;
      this.getComboItemDetail(this.item.item_id);
    });
    bus.$on("comb_item_index", data => {
      this.item_index = data;
    });
    bus.$on("comb_item_variant", data => {
      this.item_variant = data;
    });
    bus.$on("comb_item_name", data => {
      this.comb_item_name = data;
    });
    bus.$on("comb_cart_id", data => {
      this.comb_cart_id = data;
    });
    this.currency_symbol = localStorage.getItem("currency_symbol") != null && localStorage.getItem("currency_symbol") != "null"?localStorage.getItem("currency_symbol"):"₹";
    window.$(this.$refs.combomodal).on("hidden.bs.modal", this.closeModal);
    window.$(this.$refs.combomodal).on("show.bs.modal", this.openModal);
    this.originalColor();
  },
  methods: {
    addCombo(){
      this.selectedComboItems = [];
      var vm = this;
      this.combo_item_details.forEach(function(combo_items) {
        combo_items.combo_variants.forEach(function(citem) {
          if(citem.is_added == 1){
            var i=0;
            for(i=0;i<citem.quantity;i++){
            var obj = {};
            obj['combo_item_id'] = combo_items.combo_item_id;
            obj['item_name'] = citem.item_name;
            obj['combo_item_detail_id'] = citem.combo_item_detail_id;
            obj['quantity'] = 1;
            if(citem.choices != null){
              obj['choices'] = vm.filterChoices(citem.choices);
            }
            if(citem.addons != null){
              obj['addons'] = vm.filterAddons(citem.addons);
            }
            vm.selectedComboItems.push(obj);
            }
          }
        });
      });
      this.selected_data.item = this.item;
      this.selected_data.index = this.item_index;
      this.selected_data.item_variant = this.item_variant;
      this.selected_data.combo_items = this.selectedComboItems;
      this.selected_data.cart_id = this.cart_id;
      if(this.is_wishlist == 1){
        this.selected_data.combo_variants = this.combo_item_details;
      }
      this.$emit('update', this.selected_data);
      window.$('#comboOffer'+this.pid).modal("hide");
    },
    priceFormatter(value) {
      var price = value.toString().split(".")[0];
      var decimal = value.toString().split(".")[1];
      var response = this.currency_symbol + price;
      if(decimal != undefined){
        response += "<span class='price-decimal'><small>." + decimal + "</small></span>";
      }
      return response;
    },
    openModal(){
      this.selectedComboItems = [];
      this.combo_item_details = null;
      this.getComboItemDetail(this.item.item_id);
    },
    closeModal(){
      this.item = null;
      this.item_index = "";
      this.item_variant = null;
      this.comb_item_name = "";
      this.selectedComboItems = [];
      this.combo_item_details = null;
      this.$emit('closecombo', true);
    },
    getComboItemDetail(item_id) {
      this.combo_loading = true;
      this.selectedComboItems = [];
      this.combo_item_data.item_id = item_id;
      this.combo_item_data.user_id = (this.userprofile != null) ? this.userprofile.user_id : "0";
      this.$store
        .dispatch("getproductdetail", this.combo_item_data)
        .then(response => {
          this.combo_loading = false;
          var result = JSON.parse(JSON.stringify(response));
          if(result.status == 1 && result.data.combo_items != null){
            var combo_item_details = result.data.combo_items;
            var tempArr = [];
            var i = 0, j = 0;
            for(i=0;i<combo_item_details.length;i++)
            {
              var inc = 1;
              for(j=0; j<parseInt(combo_item_details[i].quantity); j++)
              {
                var tempObj = JSON.parse(JSON.stringify(combo_item_details[i]));
                tempObj.total_qty = combo_item_details[i].quantity;
                tempObj.quantity = 1;
                tempObj.inc_index = inc;
                tempArr.push(tempObj);
                inc++;
              }
            }
            this.combo_item_details = tempArr;
          }
        });
    },
    getTotalComboItemQty(combo_index){
      var total = 0;
      if(this.combo_item_details != null && this.combo_item_details != undefined && this.combo_item_details[combo_index] != null && this.combo_item_details[combo_index] != undefined)
      {
        this.combo_item_details[combo_index].combo_variants.forEach(function(item) {
          total += parseFloat(item.quantity);
        });
      }
      return total;
    },
    isComboItemAdded(combo_index, item_index){
      if (this.combo_item_details[combo_index].combo_variants[item_index].is_added == 1) {
        return true;
      } else {
        return false;
      }
    },
    checkComboSelection(combo_index, item_index){

      this.combo_item_details[combo_index].combo_variants.forEach((combo_variant, combo_variant_index) => {
         if(combo_variant.choices != null){
            combo_variant.choices.forEach((choice, ind) => {
              choice.choice_prices.forEach((item, ind1) => {
                this.combo_item_details[combo_index].combo_variants[combo_variant_index].choices[ind].choice_prices[ind1].is_added = 0;
              });
            });
         }
         if(combo_variant.addons != null){
            combo_variant.addons.forEach((addon, ind) => {
              addon.addon_prices.forEach((item, ind1) => {
                this.combo_item_details[combo_index].combo_variants[combo_variant_index].addons[ind].addon_prices[ind1].is_added = 0;
              });
            });
         }
      });

      var is_added = this.combo_item_details[combo_index].combo_variants[item_index].is_added;
      if(!this.checkMaxComboQty(combo_index) && is_added != 1){
        if (
          this.combo_item_details[combo_index].combo_variants.find(
            o => o.is_added == 1
          ) != undefined
        ) {
          let combo_variant_index = this.combo_item_details[combo_index].combo_variants.findIndex(
              o => o.is_added == 1
          );
          this.combo_item_details[combo_index].combo_variants[combo_variant_index].is_added = 0;
        }
      }

      this.selected_item_name = this.combo_item_details[combo_index].combo_variants[item_index].item_name;
      this.active_combo_index = combo_index;
      this.active_item_index = item_index;
      
      if(this.combo_item_details[combo_index].combo_variants[item_index].choices == null){
        this.active_combo_choices = [];
      } else {
        this.active_combo_choices = this.combo_item_details[combo_index].combo_variants[item_index].choices;
        if(this.active_combo_choices != null && this.active_combo_choices != undefined && this.active_combo_choices.length > 0)
        {
          var _this=this;
          this.active_combo_choices.forEach(function(activeComboChoices){
            _this.selectedChoices[_this.active_combo_choices.indexOf(activeComboChoices)] = activeComboChoices.choice_prices[0];
          })
        }
      }

      if(this.combo_item_details[combo_index].combo_variants[item_index].addons == null){
        this.active_combo_addons = [];
      } else {
        this.active_combo_addons = this.combo_item_details[combo_index].combo_variants[item_index].addons;
      }

      if(is_added == 1){
        this.active_combo_choices = [];
        this.active_combo_addons = [];
        this.combo_item_details[combo_index].combo_variants[item_index].is_added = 0;
        this.combo_item_details[combo_index].combo_variants[item_index].quantity = 0;
      } else {
        this.combo_item_details[combo_index].combo_variants[item_index].is_added = 1;
        this.combo_item_details[combo_index].combo_variants[item_index].quantity = 1;
      }

      if(this.combo_item_details[combo_index].combo_variants[item_index].choices == null && this.combo_item_details[combo_index].combo_variants[item_index].addons == null ){
        this.sortAddedComboItems(combo_index);
        var total = this.getTotalComboItemQty(combo_index);
        if(total == this.combo_item_details[combo_index].quantity){
          this.step += 1;
        }
      }
    },
    checkMaxComboQty(combo_index){
      var total = this.getTotalComboItemQty(combo_index);
      if(this.combo_item_details != null && total >= this.combo_item_details[combo_index].quantity){
        return false;
      } else {
        return true;
      }
    },
    sortAddedComboItems(combo_index){
      var is_add= this.combo_item_details[combo_index].combo_variants.filter(x=>x.is_added==1).length > 0 ? this.combo_item_details[combo_index].combo_variants.filter(x=>x.is_added==1)[0] : null;
      if(is_add != null){
        this.combo_item_details[combo_index].combo_variants = this.combo_item_details[combo_index].combo_variants.filter(x=>x.is_added!=1);
        this.combo_item_details[combo_index].combo_variants = [is_add].concat(this.combo_item_details[combo_index].combo_variants);
      }
    },
    addChoicesAddons(){

      this.combo_item_details[this.active_combo_index].combo_variants.forEach((combo_variant, combo_variant_index) => {
         if(combo_variant.choices != null){
            combo_variant.choices.forEach((choice, ind) => {
              choice.choice_prices.forEach((item, ind1) => {
                this.combo_item_details[this.active_combo_index].combo_variants[combo_variant_index].choices[ind].choice_prices[ind1].is_added = 0;
              });
            });
         }
         if(combo_variant.addons != null){
            combo_variant.addons.forEach((addon, ind) => {
              addon.addon_prices.forEach((item, ind1) => {
                this.combo_item_details[this.active_combo_index].combo_variants[combo_variant_index].addons[ind].addon_prices[ind1].is_added = 0;
              });
            });
         }
      });

      if(this.selectedChoices.length > 0){
        this.combo_item_details[this.active_combo_index].combo_variants[this.active_item_index].choices.forEach((choice, ind) => {
          choice.choice_prices.forEach((item, ind1) => {
            if(this.isChoiceAdded(this.selectedChoices, item)){
              this.combo_item_details[this.active_combo_index].combo_variants[this.active_item_index].choices[ind].choice_prices[ind1].is_added = 1;
            } else {
              this.combo_item_details[this.active_combo_index].combo_variants[this.active_item_index].choices[ind].choice_prices[ind1].is_added = 0;
            }
          });
        });
      }

      if(this.selectedAddons.length > 0){
        this.combo_item_details[this.active_combo_index].combo_variants[this.active_item_index].addons.forEach((addon, ind) => {
          addon.addon_prices.forEach((item, ind1) => { 
            if(this.isAddonAdded(this.selectedAddons, item)){
              this.combo_item_details[this.active_combo_index].combo_variants[this.active_item_index].addons[ind].addon_prices[ind1].is_added = 1;
            } else {
              this.combo_item_details[this.active_combo_index].combo_variants[this.active_item_index].addons[ind].addon_prices[ind1].is_added = 0;
            }
          });
        });
      }
      this.sortAddedComboItems(this.active_combo_index);
      this.step += 1;
      this.resetAddonChoice();
    },
    isChoiceAdded(selectedChoices, item){
      const i = selectedChoices.findIndex(o => o.variant_choice_price_id === item.variant_choice_price_id);
      if (i > -1) return true;
      else return false;
    },
    isAddonAdded(selectedAddons, item){
      const i = selectedAddons.findIndex(o => o.variant_addon_price_id === item.variant_addon_price_id);
      if (i > -1) return true;
      else return false;
    },
    resetAddonChoice(){
      this.selected_item_name = '';
      this.active_combo_choices = [];
      this.active_combo_addons = [];
      this.selectedChoices = [];
      this.selectedAddons = [];
    },
    filterChoices(choices){
      var tmpArr = [];
      choices.forEach(choice => {
        choice.choice_prices.forEach(item => { 
          if(item.is_added == 1){
            var obj = {};
            obj['variant_choice_id'] = item.variant_choice_id;
            obj['variant_choice_price_id'] = item.variant_choice_price_id;
            tmpArr.push(obj);
          }
        });
      });
      return tmpArr;
    },
    filterAddons(addons){
      var tmpArr = [];
      addons.forEach(addon => {
        addon.addon_prices.forEach(item => { 
          if(item.is_added == 1){
            var obj = {};
            obj['variant_addon_id'] = item.variant_addon_id;
            obj['variant_addon_price_id'] = item.variant_addon_price_id;
            tmpArr.push(obj);
          }
        });
      });
      return tmpArr;
    }
  }
};
</script>